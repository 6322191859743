<template>
    <div class="p-grid crud-demo">
        <Loader v-model="loading" />
        <div class="p-col-12">
            <div class="card">
                <Toast />
                <Panel header="Reportes Business Wallet">
                    <BasicFormToolbar @new="openNew" @extraer="extraer" :actions="['new', 'extraer']" />
                    <div class="grid formgrid p-fluid">
                        <div class="col-12">
                            <Fieldset legend="Datos Generales">
                                <div class="grid formgrid p-fluid">
                                    <FormDropdown
                                        v-model="tipo"
                                        @change="tipoOnChange"
                                        :label="'Tipo'"
                                        :wrapperClass="'field col-12 xl:col-3'"
                                        :options="[{ name: 'Reporte de Cliente' }, { name: 'Reporte de Transacciones' }, { name: 'Reporte de Liquidacion' }]"
                                        :optionLabel="'name'"
                                        :optionValue="'name'"
                                    ></FormDropdown>
                                    <FormDropdown v-model="my_customer" :label="'Cliente'" :wrapperClass="'col field'" v-if="tipo != 'Reporte de Liquidacion' && tipo != 'Reporte de Transacciones'" :options="customersOptions" :optionLabel="'name'" :optionValue="'id'"></FormDropdown> 
                                    <div :class="'col field'" v-if="tipo == 'Reporte de Liquidacion' || tipo == 'Reporte de Transacciones'">
                                        <label>Cliente</label>
                                        <MultiSelect v-model="my_customer" filter :options="customersOptions" optionLabel="name" placeholder="Seleciona el cliente" optionValue="id" :maxSelectedLabels="1" :selectedItemsLabel="'{0} clientes seleccionados'" />
                                    </div>
                                    <FormCalendar v-model="inicio" :showTime="tipo == 'Reporte de Transacciones'" :hourFormat="'24'" :label="'Fecha Inicio'" :wrapperClass="'field col-12 xl:col-3'" />
                                    <FormCalendar v-model="fin" :showTime="tipo == 'Reporte de Transacciones'" :hourFormat="'24'" :label="'Fecha Fin'" :wrapperClass="'field col-12 xl:col-3'" v-if="tipo != 'Reporte de Liquidacion'" />
                                </div>
                            </Fieldset>
                        </div>
                    </div>
                </Panel>
            </div>
        </div>
    </div>
</template>

<script>

require('../../../../utilities/DATE_UTILITIES.js')
// import session from '../../../../mixins/sessionMixin';
import axios from 'axios';
import BasicFormToolbar from '../../../../components/general/BasicFormToolbar.vue';
import FormCalendar from '../../../../components/general/FormCalendar.vue';
import FormDropdown from '../../../../components/general/FormDropdown.vue';
import Loader from '../../../../components/general/Loader.vue';

import { Customer } from '../../../../models/comercial/Customer';
import { ErrorToast } from '../../../../utilities/General';
export default {
    data() {
        return {
            tipo: 'Reporte de Cliente',
            inicio: new Date().getFirstDayOfMonth(),
            fin: new Date(),
            prueba: null,
            my_customer: 0,
            customers: [],
            loading: false
        };
    },
    components: {
        BasicFormToolbar,
        FormCalendar,
        Loader,
        FormDropdown,
    },
    async mounted() {
        try {
            this.loading = true;
            let internal_customers = await new Customer(this.session).all();
            internal_customers.forEach((x) => this.customers.push(x));
        } catch (error) {
            alert(error.data);
        } finally {
            this.loading = false;
        }
    },
    computed: {
        customersOptions() {
            if (this.tipo == "Reporte de Liquidacion")
                return this.customers;
            else {
                let customers = [{ id: 0, name: 'TODOS LOS CLIENTES' }];
                this.customers.forEach(customer => customers.push(customer));
                return customers;
            }
        }
    },
    methods: {
        tipoOnChange(val) {
            if(val == 'Reporte de Liquidacion')
                this.inicio = new Date();
            else
                this.inicio = new Date().getFirstDayOfMonth();
        },
        openNew() {
            this.inicio = new Date().getFirstDayOfMonth();
            this.fin = new Date();
            this.tipo = 'Reporte de Cliente';
            this.my_customer = 0;
        },
        async extraer() {
            try {
                this.loading = true;
                if (this.tipo == 'Reporte de Cliente') {
                let response = await axios({
                    url: 'BusinessWallet/CyberSource/Report/customer',
                    method: 'POST',
                    responseType: 'blob',
                    data: {
                        inicio: this.inicio,
                        fin: this.fin,
                        customer: this.my_customer.toString(),
                    },
                });
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'reporte_clientes.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            } else if (this.tipo == 'Reporte de Transacciones') {
                let response = await axios({
                    url: 'BusinessWallet/CyberSource/Report/transaction',
                    method: 'POST',
                    responseType: 'blob',
                    data: {
                        inicio: this.inicio.getUTCJSON(),
                        fin: this.fin.getUTCJSON(),
                        customers: Object.values(this.my_customer)
                    },
                });

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'reporte_transacciones.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            } else if (this.tipo == 'Reporte de Liquidacion') {
                let response = await axios({
                    url: 'BusinessWallet/CyberSource/Report/transactionliq',
                    method: 'POST',
                    responseType: 'blob', 
                    data: {
                        date: this.inicio,
                        customers: Object.values(this.my_customer)
                    },
                   
                });
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'reporte_transacciones_liq.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            }
            } catch (error) {
                //CUANDO ES UN BLOB UN ERROR SE LEE COMO UNA PROMESA
                this.$toast.add(new ErrorToast(await error.response.data.text()));
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style>
</style>